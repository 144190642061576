<template>
  <div class="hide-nav-bar">
    <!--    <v-app-bar app elevate-on-scroll flat-->
    <v-app-bar app class="z-index-6 d-flex p-relative" style="flex-direction: column"
               :style="{ marginTop: (topBannerHeight + 48) + 'px' }">
      <div id="menu-container" class="d-flex w-100 justify-space-between">
        <div id="logo-and-app-nav" class="d-flex d-lg-none visible-xs w-100 justify-space-between">
          <v-img
            class="hidden-lg-and-up align-self-center pa-0"
            contain
            max-width="30"
            max-height="30"
            :src="require(`@/assets/icons/hamburger-menu-ic.svg`)"
            @click.stop="drawer = !drawer">
          </v-img>
          <router-link to="/" class="align-self-center pl-1" aria-label="Home">
            <TopNavBarLogo/>
          </router-link>
          <div class="d-flex d-lg-none visible-xs align-center">
            <v-row class="px-0" style="display:flex;
                                      flex-wrap:nowrap;">
              <v-col id="callus" class="align-self-center pa-0 pl-1 pr-1">
                <v-btn
                  color="#00663d"
                  class="text-none font-weight-normal text-white"
                  style="color:#fff !important;padding: 0 12px;"
                  href="tel:9729729197" aria-label="Call Us">
                  <v-icon left style="margin-right:3px;">
                    mdi-phone
                  </v-icon>
                  {{ $t('CallUs') }}
                </v-btn>
              </v-col>
              <v-col class="align-self-center pa-0">
                <v-btn
                  :small="this.$vuetify.breakpoint.width<390"
                  @click="visibleLogin" text color="#12945F"
                  class="text-none font-weight-bold"
                  v-if="!this.$store.state.authenticate.UserLogged" outlined>
                  {{ $t('Login') }}
                </v-btn>
                <v-menu offset-y v-if="this.$store.state.authenticate.UserLogged">
                  <template v-slot:activator="{ on }">
               <span
                 class="menu-dropdown mr-1"
                 v-on="on">
                    {{ getUserName }}
                  <v-icon>mdi-chevron-down</v-icon>
                </span>
                  </template>
                  <v-list>
                    <v-list-item :to="{name: item.path}" class="list-item-container"
                                 v-for="(item, i) in itemsAuthorized.filter(x => x.visible)"
                                 :key="i"
                                 small>
                      <v-list-item-title class="list-item">
                        <v-badge
                          color="blue"
                          :content="+$store.state.message.unreadMessageCount"
                          v-show="item.title === 'MyMessages' && +$store.state.message.unreadMessageCount > 0">
                          {{ $t(item.title) }}
                        </v-badge>
                        <span
                          v-show="item.title !== 'MyMessages' || (item.title === 'MyMessages' && +$store.state.message.unreadMessageCount === 0) ">{{
                            $t(item.title)
                          }}</span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout" class="list-item-container">
                      <v-list-item-title class="list-item">
                        {{ $t('Logout') }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </div>
        </div>
        <div id="logo-and-app-nav-1" class="d-none d-lg-flex">
          <router-link to="/" class="align-self-center text-decoration-none" aria-label="Home">
            <TopNavBarLogo/>
          </router-link>
          <v-app-bar-nav-icon style="border: 0"
                              class="hidden-md-and-up"
                              @click.stop="drawer = !drawer">
          </v-app-bar-nav-icon>
        </div>
        <div id="menu-items" class="d-none d-lg-flex align-center">
          <router-link
            v-for="item in items.slice(0,1)"
            :key="item.title"
            class="hidden-sm-and-down item-menu"
            :to="{name: item.path}"
            text
            small
            exact
            v-show="showItemInMenu(item)">
            <v-badge
              color="blue"
              :content="$store.state.message.unreadMessageCount"
              v-show="item.title === 'MyMessages' && $store.state.message.unreadMessageCount > 0">
              {{ $t(item.title) }}
            </v-badge>
            <span
              v-show="item.title !== 'MyMessages' || (item.title === 'MyMessages' && $store.state.message.unreadMessageCount === '0') ">{{
                $t(item.title)
              }}</span>
          </router-link>
          <v-menu transition="slide-y-transition" offset-y style="border: 0 !important">
            <template v-slot:activator="{ on }">
          <span
            class="menu-dropdown text-uppercase hidden-sm-and-down text-capitalize mr-4"
            v-on="on" style="text-transform: none !important;">
              {{ $t('Service') }}
            <v-icon>mdi-chevron-down</v-icon>
          </span>
            </template>
            <v-list class="hidden-sm-and-down">
              <v-list-item :to="{name: item.path}" class="list-item-container"
                           v-for="(item, i) in servicesItems"
                           :key="i"
                           small
                           v-show="showSubitemsInMenu(item)">
                <v-list-item-title class="list-item">
                  {{ $t(item.title) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu transition="slide-y-transition" offset-y style="border: 0 !important">
            <template v-slot:activator="{ on }">
          <span
            class="menu-dropdown text-uppercase hidden-sm-and-down text-capitalize mr-4"
            v-on="on" style="text-transform: none !important;">
              {{ $t('Footer.Company') }}
            <v-icon>mdi-chevron-down</v-icon>
          </span>
            </template>
            <v-list class="hidden-sm-and-down">
              <v-list-item :to="{name: item.path}" class="list-item-container"
                           v-for="(item, i) in companyItems"
                           :key="i"
                           small
                           v-show="showSubitemsInMenu(item)">
                <v-list-item-title class="list-item">
                  {{ $t(item.title) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu transition="slide-y-transition" offset-y style="border: 0 !important">
            <template v-slot:activator="{ on }">
          <span
            class="menu-dropdown text-uppercase hidden-sm-and-down text-capitalize mr-4"
            v-on="on" style="text-transform: none !important;">
              {{ $t('TopBarResources') }}
            <v-icon>mdi-chevron-down</v-icon>
          </span>
            </template>
            <v-list class="hidden-sm-and-down">
              <v-list-item :to="{name: item.path}" class="list-item-container"
                           v-for="(item, i) in resourcesItems"
                           :key="i"
                           small
                           v-show="showSubitemsInMenu(item)">
                <v-list-item-title class="list-item">
                  {{ $t(item.title) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu transition="slide-y-transition" offset-y style="border: 0 !important">
            <template v-slot:activator="{ on }">
          <span
            class="menu-dropdown text-uppercase hidden-sm-and-down text-capitalize mr-4"
            v-on="on" style="text-transform: none !important;">
              {{ $t('TopBarSupport') }}
            <v-icon>mdi-chevron-down</v-icon>
          </span>
            </template>
            <v-list class="hidden-sm-and-down">
              <v-list-item :to="{name: item.path}" class="list-item-container"
                           v-for="(item, i) in supportItems"
                           :key="i"
                           small
                           v-show="showSubitemsInMenu(item)">
                <v-list-item-title class="list-item">
                  {{ $t(item.title) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <router-link
            v-for="item in items.slice(1,15)"
            :key="item.title"
            class="hidden-sm-and-down item-menu"
            :to="{name: item.path}"
            text
            small
            exact
            v-show="showItemInMenu(item)">
            <v-badge
              color="blue"
              :content="+$store.state.message.unreadMessageCount"
              v-show="item.title === 'MyMessages' && +$store.state.message.unreadMessageCount > 0">
              {{ $t(item.title) }}
            </v-badge>
            <span
              v-show="item.title !== 'MyMessages' || (item.title === 'MyMessages' && +$store.state.message.unreadMessageCount === 0) ">{{
                $t(item.title)
              }}</span>
          </router-link>
          <v-btn
            class="mx-2 my-2 text-capitalize"
            color="#00663D"
            :to="{name: 'AutoEstimate'}"
            outlined>
            {{ $t('GetAnEstimate') }}
          </v-btn>
        </div>
        <div id="login-section" class="d-none d-lg-flex align-center">
          <v-menu transition="slide-y-transition" offset-y style="border: 0 !important">
            <template v-slot:activator="{ on }">
          <span
            class="menu-dropdown hidden-md-and-down mr-4"
            v-on="on">
              {{ $t('SwitchToLanguage') }}
            <v-icon>mdi-chevron-down</v-icon>
          </span>
            </template>
            <v-list class="hidden-md-and-down">
              <v-list-item-group>
                <v-list-item class="list-item-container"
                             small @click="changeLocale">
                  <v-list-item-title class="list-item">
                    English
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="list-item-container"
                             small @click="changeLocale">
                  <v-list-item-title class="list-item">
                    Español
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <span @click="visibleLogin" class="hidden-md-and-down" style="cursor: pointer"
                v-if="!this.$store.state.authenticate.UserLogged">
        {{ $t('Login') }}
      </span>
<!--          <v-btn elevation="0" color="#12945F"-->
<!--                 class="hidden-md-and-down white&#45;&#45;text text-none font-weight-bold"-->
<!--                 v-if="!this.$store.state.authenticate.UserLogged" @click="openSignUp()">-->
<!--            {{ $t('GetStarted') }}-->
<!--          </v-btn>-->
          <v-menu offset-y v-if="this.$store.state.authenticate.UserLogged"
                  class="hidden-md-and-down">
            <template v-slot:activator="{ on }">
               <span
                 class="menu-dropdown hidden-md-and-down mr-1"
                 v-on="on">
                    {{ getUserName }}
                  <v-icon>mdi-chevron-down</v-icon>
                </span>
            </template>
            <v-list>
              <v-list-item :to="{name: item.path}" class="list-item-container"
                           v-for="(item, i) in itemsAuthorized.filter(x => x.visible)"
                           :key="i"
                           small>
                <v-list-item-title class="list-item">
                  <v-badge
                    color="blue"
                    :content="+$store.state.message.unreadMessageCount"
                    v-show="item.title === 'MyMessages' && +$store.state.message.unreadMessageCount > 0">
                    {{ $t(item.title) }}
                  </v-badge>
                  <span
                    v-show="item.title !== 'MyMessages' || (item.title === 'MyMessages' && +$store.state.message.unreadMessageCount === 0) ">{{
                      $t(item.title)
                    }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout" class="hidden-sm-and-down list-item-container">
                <v-list-item-title class="list-item">
                  {{ $t('Logout') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-badge
            :class="['p-relative', 'mr-3']"
            :style="{ top: $vuetify.breakpoint.mdAndDown? '16px' : '0px' }"
            color="blue"
            :content="+$store.state.message.unreadMessageCount"
            v-show="+$store.state.message.unreadMessageCount > 0">
          </v-badge>
        </div>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      color="white"
      app
      temporary
      left style="z-index: 15">
      <v-row class="mx-auto">
        <v-col>
          <v-menu transition="slide-y-transition" offset-y style="border: 0 !important">
            <template v-slot:activator="{ on }">
              <v-btn
                small
                elevation="0"
                outlined
                color="#12945f"
                class="text-none ml-0 menu-item-mobile-design"
                v-on="on">
                {{ $t('SwitchToLanguage') }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list class="hidden-lg-and-up">
              <v-list-item-group>
                <v-list-item class="list-item-container"
                             small @click="changeLocale">
                  <v-list-item-title class="list-item">
                    English
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="list-item-container"
                             small @click="changeLocale">
                  <v-list-item-title class="list-item">
                    Español
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-list nav dense height="80%">
        <v-list-item-content style="padding: 0px 0px">
          <v-list-item :to="{name: items[0].path}">
            <v-list-item-title class="menu-item-mobile-design">
                  <span
                    class="hidden-lg-and-up text-capitalize">
                      {{ $t(items[0].title) }}
                  </span>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-content>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="menu-item-mobile-design">
                <span
                  class="hidden-lg-and-up text-capitalize">
                    {{ $t('Service') }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item :to="{name: item.path}" class="list-item-container"
                       v-for="(item, i) in servicesItems"
                       :key="i"
                       small
                       v-show="showSubitemsInMenu(item)">
            <v-list-item-content>
              <v-list-item-title class="list-item">
                {{ $t(item.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="menu-item-mobile-design">
                <span
                  class="hidden-lg-and-up text-capitalize">
                    {{ $t('Footer.Company') }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item :to="{name: item.path}" class="list-item-container"
                       v-for="(item, i) in companyItems"
                       :key="i"
                       small
                       v-show="showSubitemsInMenu(item)">
            <v-list-item-content>
              <v-list-item-title class="list-item">
                {{ $t(item.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="menu-item-mobile-design">
                <span
                  class="hidden-lg-and-up text-capitalize">
                    {{ $t('TopBarResources') }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item :to="{name: item.path}" class="list-item-container"
                       v-for="(item, i) in resourcesItems"
                       :key="i"
                       small
                       v-show="showSubitemsInMenu(item)">
            <v-list-item-content>
              <v-list-item-title class="list-item">
                {{ $t(item.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="menu-item-mobile-design">
                <span
                  class="hidden-lg-and-up text-capitalize">
                    {{ $t('TopBarSupport') }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item :to="{name: item.path}" class="list-item-container"
                       v-for="(item, i) in supportItems"
                       :key="i"
                       small
                       v-show="showSubitemsInMenu(item)">
            <v-list-item-content>
              <v-list-item-title class="list-item">
                {{ $t(item.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <template>
          <!--        <v-list-item-group active-class="selected-item-menu">-->
          <!--          <v-list-item>-->
          <!--            <v-list-item-title class="menu-item-mobile-design">-->
          <!--              <v-menu transition="slide-y-transition" offset-y style="border: 0 !important">-->
          <!--                <template id="template" v-slot:activator="{ on }">-->
          <!--                <span-->
          <!--                  class="menu-dropdown text-uppercase hidden-lg-and-up text-capitalize"-->
          <!--                  v-on="on" style="text-transform: none !important">-->
          <!--                    {{ $t('Service') }}-->
          <!--                  <v-icon>mdi-chevron-down</v-icon>-->
          <!--                </span>-->
          <!--              </template>-->
          <!--              <v-list class="hidden-lg-and-up">-->
          <!--                <v-list-item :to="{name: item.path}" class="list-item-container"-->
          <!--                             v-for="(item, i) in servicesItems"-->
          <!--                             :key="i"-->
          <!--                             small-->
          <!--                             v-show="showSubitemsInMenu(item)">-->
          <!--                  <v-list-item-title class="list-item">-->
          <!--                    {{ $t(item.title) }}-->
          <!--                  </v-list-item-title>-->
          <!--                </v-list-item>-->
          <!--              </v-list>-->
          <!--            </v-menu>-->
          <!--            </v-list-item-title>-->
          <!--          </v-list-item>-->
          <!--          <v-list-item>-->
          <!--            <v-menu transition="slide-y-transition" offset-y style="border: 0 !important">-->
          <!--              <template v-slot:activator="{ on }">-->
          <!--          <span-->
          <!--            class="menu-dropdown text-uppercase hidden-lg-and-up text-capitalize mr-4"-->
          <!--            v-on="on" style="text-transform: none !important;">-->
          <!--              {{ $t('Footer.Company') }}-->
          <!--            <v-icon>mdi-chevron-down</v-icon>-->
          <!--          </span>-->
          <!--              </template>-->
          <!--              <v-list class="hidden-lg-and-up">-->
          <!--                <v-list-item :to="{name: item.path}" class="list-item-container"-->
          <!--                             v-for="(item, i) in companyItems"-->
          <!--                             :key="i"-->
          <!--                             small-->
          <!--                             v-show="showSubitemsInMenu(item)">-->
          <!--                  <v-list-item-title class="list-item">-->
          <!--                    {{ $t(item.title) }}-->
          <!--                  </v-list-item-title>-->
          <!--                </v-list-item>-->
          <!--              </v-list>-->
          <!--            </v-menu>-->
          <!--          </v-list-item>-->
          <!--          <v-list-item>-->
          <!--            <v-menu transition="slide-y-transition" offset-y style="border: 0 !important">-->
          <!--              <template v-slot:activator="{ on }">-->
          <!--                <span-->
          <!--                  class="menu-dropdown text-uppercase hidden-lg-and-up text-capitalize mr-4"-->
          <!--                  v-on="on" style="text-transform: none !important;">-->
          <!--                    {{ $t('TopBarResources') }}-->
          <!--                  <v-icon>mdi-chevron-down</v-icon>-->
          <!--                </span>-->
          <!--              </template>-->
          <!--              <v-list class="hidden-lg-and-up">-->
          <!--                <v-list-item :to="{name: item.path}" class="list-item-container"-->
          <!--                             v-for="(item, i) in resourcesItems"-->
          <!--                             :key="i"-->
          <!--                             small-->
          <!--                             v-show="showSubitemsInMenu(item)">-->
          <!--                  <v-list-item-title class="list-item">-->
          <!--                    {{ $t(item.title) }}-->
          <!--                  </v-list-item-title>-->
          <!--                </v-list-item>-->
          <!--              </v-list>-->
          <!--            </v-menu>-->
          <!--          </v-list-item>-->
          <!--          <v-list-item>-->
          <!--            <v-menu transition="slide-y-transition" offset-y style="border: 0 !important">-->
          <!--              <template v-slot:activator="{ on }">-->
          <!--          <span-->
          <!--            class="menu-dropdown text-uppercase hidden-lg-and-up text-capitalize mr-4"-->
          <!--            v-on="on" style="text-transform: none !important;">-->
          <!--              {{ $t('TopBarSupport') }}-->
          <!--            <v-icon>mdi-chevron-down</v-icon>-->
          <!--          </span>-->
          <!--              </template>-->
          <!--              <v-list class="hidden-lg-and-up">-->
          <!--                <v-list-item :to="{name: item.path}" class="list-item-container"-->
          <!--                             v-for="(item, i) in supportItems"-->
          <!--                             :key="i"-->
          <!--                             small-->
          <!--                             v-show="showSubitemsInMenu(item)">-->
          <!--                  <v-list-item-title class="list-item">-->
          <!--                    {{ $t(item.title) }}-->
          <!--                  </v-list-item-title>-->
          <!--                </v-list-item>-->
          <!--              </v-list>-->
          <!--            </v-menu>-->
          <!--          </v-list-item>-->
          <!--          &lt;!&ndash;          <template v-for="(item, index) in items">&ndash;&gt;-->
          <!--          &lt;!&ndash;            <router-link :key="index" :to="{name: item.path}"&ndash;&gt;-->
          <!--          &lt;!&ndash;                         v-show="showItemInMobileMenu(item)"&ndash;&gt;-->
          <!--          &lt;!&ndash;                         :class="['black&#45;&#45;text', 'text-decoration-none', item.title == 'Feq'? 'text-uppercase': '']">&ndash;&gt;-->
          <!--          &lt;!&ndash;              <v-list-item>&ndash;&gt;-->
          <!--          &lt;!&ndash;                <v-badge&ndash;&gt;-->
          <!--          &lt;!&ndash;                  color="red"&ndash;&gt;-->
          <!--          &lt;!&ndash;                  :content="$store.state.message.unreadMessageCount"&ndash;&gt;-->
          <!--          &lt;!&ndash;                  v-show="item.title === 'MyMessages' && $store.state.message.unreadMessageCount > 0">&ndash;&gt;-->
          <!--          &lt;!&ndash;                  <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>&ndash;&gt;-->
          <!--          &lt;!&ndash;                </v-badge>&ndash;&gt;-->
          <!--          &lt;!&ndash;                <v-list-item-title&ndash;&gt;-->
          <!--          &lt;!&ndash;                  class="menu-item-mobile-design"&ndash;&gt;-->
          <!--          &lt;!&ndash;                  v-show="item.title !== 'MyMessages' || (item.title === 'MyMessages' && $store.state.message.unreadMessageCount === '0') ">&ndash;&gt;-->
          <!--          &lt;!&ndash;                  {{ $t(item.title) }}&ndash;&gt;-->
          <!--          &lt;!&ndash;                </v-list-item-title>&ndash;&gt;-->
          <!--          &lt;!&ndash;              </v-list-item>&ndash;&gt;-->
          <!--          &lt;!&ndash;            </router-link>&ndash;&gt;-->
          <!--          &lt;!&ndash;          </template>&ndash;&gt;-->
          <!--        </v-list-item-group>-->
        </template>
      </v-list>
      <v-container class="text-center">
        <v-btn elevation="0" color="#12945F"
               class="white--text text-none font-weight-bold justify-center"
               style="padding: 0 5px"
               href="tel:9729729197">
          <v-icon>call</v-icon>
          {{ $t('CallUs') }}
        </v-btn>
      </v-container>
    </v-navigation-drawer>
    <login-form></login-form>
  </div>
</template>

<script>
import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import LoginForm from '@/components/account/LoginForm.vue';
import i18n from '@/plugins/i18n';
import webConstant from '@/api/config/webConstant';
import EventHelper from '@/event-helper';
import TopNavBarLogo from "@/components/TopNavBarLogo.vue";

export default {
  name: 'TopNavBar',
  components: {
    TopNavBarLogo,
    LoginForm,
  },
  props: {
    topBannerHeight: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      appTitle: 'UcarMobile',
      drawer: false,
      unreadMessages: 0,
      userDealerOrSalesMan: false,
      userId: 0,
      items: [
        {
          title: 'CitiesWeServe.TopBarItem',
          path: 'CitiesWeServe',
          desktopVisible: true,
        },
        {
          title: 'Service',
          path: 'MobileServices',
          desktopVisible: false,
        },
        {
          title: 'TopBarTeam',
          path: 'Team',
          desktopVisible: false,
        },
        {
          title: 'TopBarWhyMobile',
          path: 'WhyMobileServices',
          desktopVisible: false,
        },
        {
          title: 'TopBarBlog',
          path: 'Blog',
          desktopVisible: false,
        },
        {
          title: 'TopBarInsights',
          path: 'Insights',
          desktopVisible: false,
        },
        {
          title: 'TopBarCarriers',
          path: 'Careers',
          desktopVisible: false,
        },
        {
          title: 'ContactUs',
          path: 'ContactUs',
          desktopVisible: false,
        },
        {
          title: 'AboutUs',
          path: 'AboutUs',
          desktopVisible: false,
        },
        {
          title: 'Feq',
          path: 'Faq',
          desktopVisible: false,
        },
        {
          title: 'HotlineForTechs',
          path: 'HotlineForTechs',
          desktopVisible: false,
        },
      ],
      languages: [
        {
          flag: 'us',
          language: 'en',
          title: 'En',
        },
        {
          flag: 'es',
          language: 'es',
          title: 'Es',
        },
      ],
      servicesItems: [
        {
          title: 'TopBarWhyMobile',
          path: 'WhyMobileServices',
        },
        {
          title: 'ViewAllServices',
          path: 'MobileServices',
        },
      ],
      companyItems: [
        {
          title: 'AboutUs',
          path: 'AboutUs',
        },
        {
          title: 'TopBarTeam',
          path: 'Team',
        },
        {
          title: 'TopBarInsights',
          path: 'Insights',
        },
        {
          title: 'TopBarCarriers',
          path: 'Careers',
        },
      ],
      resourcesItems: [
        {
          title: 'TopBarBlog',
          path: 'Blog',
        },
        {
          title: 'Feq',
          path: 'Faq',
        },
        {
          title: 'HotlineForTechs',
          path: 'HotlineForTechs',
        },
      ],
      supportItems: [
        {
          title: 'ContactUs',
          path: 'ContactUs',
        },
      ],
      itemsAuthorized: [
        {
          title: 'MyAppointments',
          path: 'MyAppointments',
          visible: true,
        },
        {
          title: 'MyMessages',
          path: 'MyMessages',
          visible: true,
        },
      ],
    };
  },
  mounted() {
    this.deleteOldCookies();
    this.checkUserLogged();
    this.subscribeReceiveUnreadMessages();
    this.getUnreadMessage();
    EventHelper.$on('onUserSigned', () => {
      this.refreshMenuItemsAuthorized();
    });
  },
  methods: {
    async logout() {
      localStorage.removeItem('userName');
      localStorage.removeItem('sessionId');
      localStorage.removeItem('nearbylocationaddress');
      localStorage.removeItem('zipcode');
      localStorage.removeItem('dealerId');
      localStorage.removeItem('isEnrolled');
      localStorage.removeItem('mobile-isAdmin');
      localStorage.removeItem('mobile-dealerId');
      localStorage.removeItem('mobile-salesPersonId');
      await this.$store.dispatch('authenticate/logout');
      // Delete old cookies
      this.deleteOldCookies();
      this.$store.state.authenticate.TriggeredFromStepper = false;
      await this.$router.push({ name: 'Home' });
    },
    visibleLogin() {
      this.$store.state.authenticate.TriggeredFromStepper = false;
      this.$store.dispatch('authenticate/setVisibleLogin', true);
      this.$store.dispatch('authenticate/setVisibleSignUp', false);
      this.$store.dispatch('authenticate/changeLoginStep', 1);
    },
    changeLocale() {
      if (i18n.locale === 'en') {
        i18n.locale = 'es';
        localStorage.setItem('lang', 'es');
        window.location.reload();
      } else {
        i18n.locale = 'en';
        localStorage.setItem('lang', 'en');
        window.location.reload();
      }
    },
    deleteOldCookies() {
      this.$cookies.remove('userName');
      this.$cookies.remove('sessionId');
      this.$cookies.remove('nearbylocationaddress');
      this.$cookies.remove('zipcode');
      this.$cookies.remove('dealerId');
      this.$cookies.remove('isEnrolled');
      this.$cookies.remove('mobile-isAdmin');
      this.$cookies.remove('mobile-dealerId');
      this.$cookies.remove('mobile-salesPersonId');
    },
    refreshMenuItemsAuthorized() {
      this.itemsAuthorized = [
        {
          title: 'MyAppointments',
          path: 'MyAppointments',
          visible: true,
        },
        {
          title: 'MyMessages',
          path: 'MyMessages',
          visible: true,
        },
      ];
    },
    isUserDealerOrSalesMan() {
      const dealer = parseInt(localStorage.getItem('mobile-dealerId')
        ?.toString() || '0', 0);
      const salesMan = parseInt(localStorage.getItem('mobile-salesPersonId')
        ?.toString() || '0', 0);
      return (dealer > 0 || salesMan > 0);
    },
    showItemInMenu(item) {
      const isUserLogged = this.userLogged;
      return ((item.path === 'MyAppointments' && isUserLogged)
        || (item.path === 'MyMessages' && isUserLogged)
        || (item.path !== 'MyAppointments' && item.path !== 'EnrollBuyer' && item.path !== 'MyMessages' && item.desktopVisible === true));
    },
    showItemInMobileMenu(item) {
      const isUserLogged = this.userLogged;
      const dealer = this.isDealer;
      return (((item.path === 'MyAppointments' && isUserLogged)
          || (item.path === 'EnrollBuyer' && isUserLogged && dealer))
        || ((item.path !== 'MyAppointments') && item.path !== 'EnrollBuyer'));
    },
    showSubitemsInMenu(item) {
      const isUserLogged = this.userLogged;
      const dealer = this.isDealer;
      return ((item.path === 'EnrollBuyer' && isUserLogged && dealer)
        || (item.path !== 'EnrollBuyer'));
    },
    subscribeReceiveUnreadMessages() {
      const connection = new HubConnectionBuilder()
        .withUrl(`${webConstant.API_BASE_URL.split('api')[0]}chat`)
        .configureLogging(LogLevel.Information)
        .build();
      let startedPromise = null;

      function start() {
        startedPromise = connection.start()
          .catch(() => new Promise((resolve, reject) => setTimeout(() => start()
            .then(resolve)
            .catch(reject), 5000)));
        return startedPromise;
      }

      connection.onclose(() => start());
      start();
      connection.on('ReceiveMessage', (userId, unreadMessageCount) => {
        const userIds = localStorage.getItem('sessionId');
        if (userIds && userId === userIds) {
          this.unreadMessages = unreadMessageCount;
          this.$store.dispatch('message/setUnreadMessage', unreadMessageCount);
        }
      });
    },
    getUnreadMessage() {
      const userId = localStorage.getItem('sessionId');
      if (userId !== null) {
        this.$store.dispatch('message/getUnreadMessages', userId.toString())
          .then((resp) => {
            this.unreadMessages = resp;
            this.$store.dispatch('message/setUnreadMessage', this.unreadMessages);
          });
      }
    },
    callUs() {
      return 'tel:9729729197';
    },
    openSignUp() {
      this.$store.state.authenticate.TriggeredFromStepper = false;
      this.$store.dispatch('authenticate/setVisibleLogin', false);
      this.$store.dispatch('authenticate/setVisibleSignUp', true);
      this.$store.dispatch('authenticate/changeLoginStep', 1);
    },
    checkUserLogged() {
      if (localStorage.getItem('sessionId') !== null) {
        this.$store.dispatch('authenticate/setUserSigned');
      }
    },
  },
  computed: {
    getUserName() {
      return (this.$store.state.authenticate.UserLoggedInformation.userName !== '')
        ? this.$store.state.authenticate.UserLoggedInformation.userName
        : localStorage.getItem('userName');
    },
    userLogged() {
      return this.$store.state.authenticate.UserLogged;
    },
    isDealer() {
      return this.$store.state.authenticate.UserLoggedInformation.dealerId !== 0;
    },
    unreadMessageCountComputed() {
      return this.$store.state.message.unreadMessageCount;
    },
  },
};
</script>

<style scoped>

button {
  padding: 5px;
  border: 1px solid green;
  font-size: 12px;
  margin: 10px;
}

button.menu-dropdown {
  margin: 0;
  border: 0;
}

.list-item {
  font-size: 0.75rem;
  font-weight: 500;
  padding: 5px;
  color: black;
}

.list-item-container {
  min-height: 28px !important;
}

.v-toolbar__content {
  justify-content: space-between !important;
}

#logo-button {
  padding: 3px;
  border: 0;
  letter-spacing: 0;
  font-weight: bold;
  font-size: 1rem;
}

#logo-button.theme--light.v-btn--active:hover::before, #logo-button.theme--light.v-btn--active::before {
  opacity: 0 !important;
}

#logo-button:hover {
  background: transparent;
}

.margin-top-76 {
  margin-top: 76px !important;
}

@media (max-width: 956px) {
  header {
    top: 180px !important;
  }
}

@media (min-width: 960px) and (max-width: 956px) {
  header {
    top: 0 !important;
  }
}

@media (max-width: 1263px) {
  header {
    top: 10px !important;
  }
}

#callus a {
  color: #fff !important;
}

</style>
